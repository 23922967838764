<!-- 组件说明 -->
<template>
    <div class="manage" v-loading="loading">
        <div class="opt_path" style="margin-top: 20px">
            <span @click="$router.push({ path: `/manage` })">学生管理</span>
            <span @click="$router.push({ path: `/manage/class` })">
                标签管理
            </span>
            <span>标签详情</span>
        </div>
        <div class="work_list" v-if="total > 0">
            <el-table class="pd20" ref="multipleTable" :data="workList" highlight-current-row
                :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" />
                <el-table-column label="学员" width="240px">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatar | imgFormat" style="
                                height: 60px;
                                width: 60px;
                                margin-right: 10px;
                            " />
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" width="120px">
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="所属标签">
                    <template slot-scope="scope">
                        <span>{{ scope.row.groupName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="加入时间">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.updateTime }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <span @click="batchDelTag(scope.row)">
                                从该标签移出
                            </span>
                            <span @click="batchDelSchool(scope.row.id)">
                                移出学校
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="total == 0 && !loading" class="no_data">
            <div class="empty">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
            </div>
            <p class="tc">暂无数据</p>
        </div>
        <div class="page_part ls_flexalign_center">
            <div class="ls_flex">
                <el-button @click="
                    showBatchClass = true;
                batchClass = [];
                ">
                    批量改标签
                </el-button>
                <el-button @click="batchDelSchool()">批量移出学校</el-button>
            </div>
            <div class="ls_flex_1"></div>
            <el-pagination v-show="total > 0" :current-page="params.pageIndex" :page-sizes="[10, 15, 20, 25]"
                :page-size="params.pageSize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <el-dialog title="批量修改标签" :visible.sync="showBatchClass" width="500px" top="10vh" :close-on-click-modal="false">
            <el-checkbox-group v-model="batchClass">
                <el-checkbox :label="item.id" v-for="(item, index) in courseList.filter(item => {
                    return item.id !== -1;
                })" :key="index">
                    {{ item.name }}
                </el-checkbox>
            </el-checkbox-group>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showBatchClass = false">取消</el-button>
                <el-button type="primary" @click="createBatchStatus(2)">
                    确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data () {
        return {
            btnShow: true,
            params: {
                groupId: this.$route.query.id,

                pageIndex: 1,
                pageSize: 10,
            },

            showBatch: false,
            temp: {
                avatar: "",
                groupId: "",
                className: "",
                hasAssistant: "",
                id: "",
                phoneNumber: "",
                examineStatus: "",
                name: "",
                studentNumber: "",
                studyStatus: "",
                updateTime: "",
            },
            studyStatus: "",
            statusList: [
                {
                    name: "全部状态",
                    value: -1,
                },
                {
                    name: "在读中",
                    value: 0,
                },
                {
                    name: "已毕业",
                    value: 1,
                },
            ],
            auditList: [
                {
                    name: "未审核",
                    value: "WAIT",
                },
                {
                    name: "已通过",
                    value: "ALL",
                },
                {
                    name: "已驳回",
                    value: "REJECT",
                },
            ],
            activeName: 0,
            courseList: [],
            classList: [],
            workList: [],
            classDataList: [],
            total: null,
            totalClass: null,
            loading: false,
            classShow: false,
            className: "",
            classTitle: "",
            groupId: "",
            name: "",
            checkedAll: false,
            showBatchClass: false,
            batchAuditing: "",
            batchStatus: "",
            batchClass: [],
            multipleSelection: [],
            isShowEmpty: false,
        };
    },
    //监控data中的数据变化
    watch: {},
    //监听属性 类似于data概念
    computed: {},
    methods: {
        batchDelTag (item) {
            if (item && item.groupIdsList.length < 2) {
                this.$message({ message: "该学员只存在一个标签无法移除！" });
                return;
            }

            this.$confirm(
                `<div>
                    <div class="lh26 fz14">学员将被移出标签</div>
                    <div class="lh26 fz14">建议：不想让学院看到此标签下的内容可以移除</div>
                </div>`,
                "移出标签",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    dangerouslyUseHTMLString: true,
                }
            ).then(() => {
                this.$ajax
                    .post("/group/student/delete", {
                        id: item.id,
                        groupId: this.params.groupId,
                    })
                    .then(result => {
                        this.$message({
                            message: "移除成功",
                            type: "success",
                        });
                        this.getList();
                    });
            });
        },
        // 移出学校
        batchDelSchool (id) {
            this.$confirm(
                `<div>
                    <div class="lh26 fz14">被移出学校的学员无法使用系统</div>
                    <div class="lh26 fz14">建议：如非本校学员，或毕业学员可以移出</div>
                </div>`,
                "移出学校",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    dangerouslyUseHTMLString: true,
                }
            ).then(() => {
                let ids = [];
                if (id) {
                    ids = [id];
                } else {
                    var studentIds = JSON.parse(
                        JSON.stringify(this.multipleSelection)
                    );
                    if (studentIds.length == 0) {
                        this.$message({
                            message: "请先选择学生",
                            type: "warning",
                        });
                        return;
                    }
                    studentIds.forEach(element => {
                        ids.push(element.id);
                    });

                    console.log(ids);
                }
                this.$ajax
                    .post("/user/teacher/delete", { ids })
                    .then(result => {
                        this.$message({
                            message: "移除成功",
                            type: "success",
                        });
                        this.getList();
                    });
            });
        },
        getList () {
            this.loading = true;
            this.$ajax
                .post("/group/student/getList", this.params)
                .then(response => {
                    var pageTotal = Math.ceil(
                        response.total / this.params.pageSize
                    );
                    if (pageTotal == 0) {
                        this.params.pageIndex = 1;
                    }
                    if (
                        this.params.pageIndex > pageTotal &&
                        this.params.pageIndex != 1
                    ) {
                        this.params.pageIndex = pageTotal;
                        this.getList();
                    } else {
                        this.workList = response.list;
                        this.total = response.total;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        handleSizeChange (val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange (val) {
            this.params.pageIndex = val;
            this.getList();
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        //批量修改状态/标签
        createBatchStatus (type) {
            var studentIds = JSON.parse(JSON.stringify(this.multipleSelection));
            if (studentIds.length == 0) {
                this.$message({
                    message: "请先选择学生",
                    type: "warning",
                });
                return;
            }
            var ids = [];
            studentIds.forEach(element => {
                ids.push(element.id);
            });

            if (type == 2) {
                if (!this.batchClass.length) {
                    this.$message({
                        message: "请选择标签",
                        type: "warning",
                    });
                    return;
                }
                this.$ajax
                    .post("/user/student/my/editGroup", {
                        groupIds: this.batchClass,
                        groupId: this.params.groupId,
                        ids: ids,
                    })
                    .then(res => {
                        this.$message({
                            message: "批量修改标签成功",
                            type: "success",
                        });
                        this.showBatchClass = false;
                        this.getList();
                    });
            }
        },
        //重置
        resetBatch () {
            this.showBatch = !this.showBatch;
            this.checkedAll = false;

            this.$refs.multipleTable.clearSelection();
        },
        allCheck () {
            if (this.checkedAll) {
                this.workList.forEach((item, index) => {
                    this.$refs.multipleTable.toggleRowSelection(
                        this.workList[index],
                        true
                    );
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created () {
        this.loading = true;
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = JSON.parse(JSON.stringify(response.data));
            this.courseList.unshift({ name: "全部标签", id: -1 });
        });
        this.getList();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
};
</script>
<style lang="scss">
.poster .el-button {
    width: 326px;
    background: #5d5cd4;
    border-color: #5d5cd4;
}
</style>
<style lang="scss" scoped>
#qrcode {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 27px;
    top: 265px;
}

.poster {
    width: 370px;
    height: 538px;
    background-image: url("~@/assets/imgs/poster.png");
    background-size: cover;
    position: relative;
    padding-top: 432px;
    box-sizing: border-box;
}

.info_pop {
    img {
        height: 200px;
        width: 150px;
        margin-right: 20px;
    }

    div {
        font-size: 16px;

        +div {
            margin-top: 10px;
        }
    }
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
            width: 120px;
        }

        &:nth-child(4) {
            width: 220px;
        }

        &:nth-child(7) {
            width: 220px;
        }
    }
}

.manage {
    padding-right: 30px;
    //   padding-top: 40px;
}

.work_list {
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
}
</style>
